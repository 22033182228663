<template>
  <div class="data-monitor-wrapper">
    <div class="has-data-wrapper" v-if="hasData">
      <div class="basic-info-wrapper">
        <div class="control-info">
          <span class="control-title">远程控制</span>
          <span v-if="!controlDetail" class="control-tip">未配置</span>
          <div class="switch-btn">
            <van-switch v-model="checked" :disabled='!controlDetail' @change='handleSwitch' active-color="#07c160" inactive-color="#ee0a24" />
          </div>
        </div>
        <div>
          <van-collapse v-model="activeNames">
            <van-collapse-item title="上一次操作结果" name="1">
              <div v-if="detailLog">
                <div>
                  <span>设备状态：</span>
                  <span v-if="detailLog.dataValue==2" style="color:#F56C6C">关</span>
                  <span v-if="detailLog.dataValue==1" style="color:#67C23A">开</span>
                </div>
                <div>
                  <span>操作结果：</span>
                  <span v-if="detailLog.handleType==0" style="color:#409EFF">处理中</span>
                  <span v-else-if="detailLog.handleType==1" style="color:#67C23A">处理成功</span>
                  <span v-else style="color:#F56C6C">处理失败</span>
                </div>

                <div>
                  <div v-if="detailLog.handleType!='0'&&detailLog.handleType!='1'">
                    <span>操作描述：</span>
                    <span>{{detailLog.iotMessage}}</span>
                  </div>
                </div>
                <div>
                  <span>操作时间：</span>
                  <span>{{detailLog.sendTime}}</span>
                </div>
                <div>
                  <span>响应时间：</span>
                  <span>{{detailLog.updateTime}}</span>
                </div>
              </div>
            </van-collapse-item>
          </van-collapse>
        </div>
        <div class="title">
          <h2>基础信息</h2>
          <!-- <div class="switch-btn">
            <span>仅看关键数据</span>
            <van-switch v-model="checked" active-color="#07c160" inactive-color="#ee0a24" />
          </div> -->
        </div>
        <div class="list">
          <ListItem :title="item.dataName" v-for="(item, index) of listData" :key="item.itemCode" :index="index" @handleSelectItem="handleSelectItem(item, index)">
            <div class="list-content" slot="value">
              <div class="value">{{ item.val }}</div>
              <div class="uni"> <span v-if="item.unit==null">&nbsp;</span> <span v-else>{{item.unit}}</span></div>
              <div class="minAndMax"><span v-if="item.valMin&&item.valMin">{{item.valMin +'-'+item.valMax}}</span><span v-else>&nbsp;</span></div>
            </div>
          </ListItem>
        </div>
      </div>
      <div class="trend-wrapper">
        <div class="title">
          <h2>近24小时趋势</h2>
        </div>
        <div class="table">
          <div class="table-title">
            <h3>{{ listData[currentIndex].dataName }}</h3>
            <span>{{ listData[currentIndex].unit }}</span>
          </div>
          <div class="table-content" ref="lineEcharts"></div>
        </div>
      </div>
    </div>
    <NoData v-if="!hasData" type="NoData" />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import NoData from '@/components/NoData'
import Vue from 'vue'
import { Toast, Notify } from 'vant'
import { Collapse, CollapseItem } from 'vant'
import { Switch } from 'vant'
import 'vant/lib/switch/style'
import ListItem from './components/ListItem'
import {
  getDataMonitorDetail,
  getDataMonitorHistoryDetail,
  deviceControlActionConfig,
  deviceControlActionConfig_control,
} from '@/api/deviceDetail'

let echarts = require('echarts/lib/echarts')
require('echarts/lib/chart/line')
Vue.use(Toast, Notify)

Vue.use(Switch)
Vue.use(Collapse)
Vue.use(CollapseItem)
export default {
  name: 'DataMonitor',
  components: {
    ListItem,
    NoData,
  },
  data() {
    return {
      checked: false,
      deviceId: '',
      listData: [
        {
          itemName: '',
          unit: '',
        },
      ],
      currentIndex: 0,
      hasData: true,
      timeArray: [],
      valArray: [],
      controlDetail: null,
      activeNames: [],
      detailLog: null,
    }
  },
  mounted() {
    this.deviceId = this.$route.query.id
    this.getDataMonitorRequest()
    this.getDeviceControlActionConfig()
  },
  methods: {
    ...mapActions('loading', ['setLoading']),
    /**
     * 选择
     */
    handleSelectItem(item, index) {
      this.currentIndex = index
      // this.checked = this.listData[this.currentIndex].isCrux == 1 ? true : false
      this.getDataMonitorHistoryDetail(item.dataCode)
    },
    /**
     * 绘制折线图
     */
    drawLine() {
      // 基于准备好的dom，初始化echarts实例
      var myChart = echarts.init(this.$refs.lineEcharts)

      // 指定图表的配置项和数据
      var option = {
        grid: {
          left: 100,
          top: 20,
        },
        xAxis: {
          name: '(h)',
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          axisLabel: {
            color: '#666666',
            fontSize: 12,
          },
          data: this.timeArray,
        },
        yAxis: {
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          axisLabel: {
            color: '#666666',
            fontSize: 12,
          },
        },
        series: [
          {
            name: '销量',
            type: 'line',
            symbolSize: 0,
            lineStyle: {
              color: '#4A8CFF',
            },
            areaStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: '#4A8CFF', // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: '#FFFFFF', // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
            },
            markPoint: {
              data: [{ type: 'max', name: '最大值' }],
            },
            markLine: {
              data: [{ type: 'average', name: '平均值' }],
            },
            data: this.valArray,
          },
        ],
      }

      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option)
    },
    /**
     * 获取数据监控
     */
    getDataMonitorRequest() {
      this.setLoading(true)
      getDataMonitorDetail(this.deviceId)
        .then((res) => {
          if (res.length > 0) {
            this.hasData = true
            this.listData = res
            this.drawLine()
            // this.checked = this.listData[this.currentIndex].isCrux == 1 ? true : false
            if (this.listData.length != '0') {
              this.handleSelectItem(res[0], 0)
            }
          } else {
            this.hasData = false
          }
        })
        .catch(() => {})
        .finally(() => {
          this.setLoading(false)
        })
    },
    getDataMonitorHistoryDetail(clctAddr) {
      this.timeArray = []
      this.valArray = []
      let param = {
        clctAddr,
        deviceId: this.deviceId,
      }
      getDataMonitorHistoryDetail(param).then((res) => {
        res.map((item) => {
          this.timeArray.push(item.timestr)
          this.valArray.push(item.sval)
        })
        this.drawLine()
      })
    },
    getDeviceControlActionConfig() {
      deviceControlActionConfig(this.deviceId).then((res) => {
        let details = res.details
        this.controlDetail = details
        if (details.dataType == 6) {
          this.checked = details.dataValue == 1 ? true : false
        }
        if (details && details.dcLog) {
          this.detailLog = details.dcLog
        }
      })
    },
    handleSwitch() {
      let param = {
        id: this.controlDetail.id,
        deviceId: this.controlDetail.deviceId,
        dataCode: this.controlDetail.dataCode,
        dataValue: this.controlDetail.dataValue == 1 ? '2' : '1',
        retainValue: this.controlDetail.retainValue,
      }

      deviceControlActionConfig_control(param).then((res) => {
        if (res.retCode == 1) {
          Notify({ type: 'success', message: res.retMsg })
          this.getDeviceControlActionConfig()
        } else {
          Notify({ type: 'danger', message: res.retMsg })
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../style/mixin';
.data-monitor-wrapper {
  width: 100%;
  height: 100%;
  .has-data-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .basic-info-wrapper {
    // height: 0;
    // flex: 1;
    display: flex;
    flex-direction: column;
    .control-info {
      padding: 0.5rem;
      background: #fff;
      display: flex;
      align-items: center;

      .control-title {
        font-size: 0.7rem;
        color: black;
        margin-right: 0.5rem;
      }
      .control-tip {
        font-size: 0.7rem;
        color: red;
        margin-right: 0.5rem;
      }
      .switch-btn {
        flex: 1;
        text-align: right;
        span {
          font-size: 0.7rem;
          color: black;
          margin-right: 0.5rem;
        }
        /deep/.van-switch {
          width: 2.8rem;
          height: 1.4rem;
          .van-switch__node {
            width: 1.4rem;
            height: 1.4rem;
          }
        }
      }
    }
    .title {
      @include wh(100%, 2.2rem);
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      padding: 0 1rem;
      border-top: 1px solid #e6e6e6;
      border-bottom: 1px solid #e6e6e6;
      h2 {
        font-size: 0.8rem;
        color: black;
      }
      .switch-btn {
        display: flex;
        align-items: center;
        span {
          font-size: 0.7rem;
          color: black;
          margin-right: 0.5rem;
        }
        /deep/.van-switch {
          width: 2.8rem;
          height: 1.4rem;
          .van-switch__node {
            width: 1.4rem;
            height: 1.4rem;
          }
        }
      }
    }
    .list {
      height: 0;
      flex: 1;
      background-color: white;
      overflow-y: auto;
      .list-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        box-sizing: border-box;
        padding-left: 1rem;
        .value {
          flex: 1;
          font-size: 0.7rem;
          color: #333333;
          &.red {
            color: #ff3333;
          }
        }
        .uni {
          flex: 1;

          font-size: 0.7rem;
          color: #999999;
        }
        .minAndMax {
          flex: 1;
          font-size: 0.7rem;
          color: #666;
        }
      }
    }
  }
  .trend-wrapper {
    .title {
      @include wh(100%, 2.2rem);
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      padding: 0 1rem;
      border-top: 1px solid #e6e6e6;
      border-bottom: 1px solid #e6e6e6;
      h2 {
        font-size: 0.8rem;
        color: black;
      }
    }
    .table {
      background-color: white;
      .table-title {
        @include wh(100%, 2.2rem);
        box-sizing: border-box;
        padding: 0 1rem;
        display: flex;
        align-items: center;
        h3 {
          font-size: 0.7rem;
          color: black;
          margin-right: 0.5rem;
        }
        span {
          font-size: 0.6rem;
          color: #999999;
        }
      }
      .table-content {
        @include wh(100%, 13rem);
      }
    }
  }
}
</style>
