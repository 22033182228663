<template>
  <div class="device-detail-wrapper">
    <div class="tab-wrapper" ref="tabs">
      <van-tabs v-model="active">
        <van-tab title="数据监控"></van-tab>
        <van-tab title="设备控制"></van-tab>
        <van-tab title="告警记录"></van-tab>
        <van-tab title="维修日志"></van-tab>
        <van-tab title="设备信息"></van-tab>
        <van-tab title="关联设备"></van-tab>
      </van-tabs>
    </div>
    <div class="detail-content">
      <!--数据监控-->
      <DataMonitor v-if="active == 0" />
      <!--设备控制-->
      <DeviceControl v-if="active == 1" />
      <!--告警记录-->
      <WarnLog v-if="active == 2" />
      <!--维修日志-->
      <DeviceLog v-if="active == 3" />
      <!--设备信息-->
      <DeviceInfo v-if="active == 4" />
      <!--关联设备-->
      <ConectDevice v-if="active == 5" />
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { Tab, Tabs } from 'vant'
import 'vant/lib/tab/style'
import 'vant/lib/tabs/style'

import DataMonitor from './DataMonitor'
import DeviceControl from './DeviceControl'
import WarnLog from './WarnLog'
import DeviceLog from './DeviceLog'
import DeviceInfo from './DeviceInfo'
import ConectDevice from './ConectDevice'

Vue.use(Tab).use(Tabs)
export default {
  name: 'DeviceDetail',
  components: {
    DataMonitor,
    DeviceControl,
    WarnLog,
    DeviceLog,
    DeviceInfo,
    ConectDevice,
  },
  mounted() {
    let query = this.$route.query
    if (query.hasOwnProperty('active')) {
      this.active = Number(query.active)
    }
  },
  data() {
    return {
      active: 0,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '../../style/mixin';
.device-detail-wrapper {
  @include wh(100%, 100%);
  background-color: #f1f1f1;
  position: relative;
  box-sizing: border-box;
  padding-top: 44px;
  .tab-wrapper {
    @include wh(100%, 44px);
    position: absolute;
    left: 0;
    top: 0;
    /deep/.van-tabs__nav {
      .van-tab__text {
        color: black;
      }
      .van-tab--active {
        .van-tab__text {
          color: #4a8cff;
        }
      }
      .van-tabs__line {
        background-color: #4a8cff;
      }
    }
  }
  .detail-content {
    @include wh(100%, 100%);
    overflow-y: auto;
  }
}
.router-fade-enter-active,
.router-fade-leave-active {
  transition: opacity 0.3s;
}
.router-fade-enter,
.router-fade-leave-active {
  opacity: 0;
}
</style>
