<template>
  <div class="list-item-wrapper" @click="handleSelectItem">
    <div :class="`item-wrapper ${borderBottom ? 'border-line' : ''}`" @click="handleListItemClick">
      <div class="left">
        <div class="icon">
          <slot name="icon"></slot>
        </div>
        <div class="title">{{title}}</div>
      </div>
      <div class="right">
        <slot name="value"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ListItem',
  props: {
    title: {
      type: String,
      default: '',
    },
    borderBottom: {
      type: Boolean,
      default: true,
    },
    itemStyle: {
      type: Object,
      default() {
        return {}
      },
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    /**
     * 点击事件
     */
    handleListItemClick() {
      this.$emit('handleListItemClick')
    },
    /**
     * 选择
     */
    handleSelectItem() {
      this.$emit('handleSelectItem', this.index)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../../style/mixin';
.list-item-wrapper {
  @include wh(100%, 2.2rem);
  box-sizing: border-box;
  padding-left: 1rem;
  .item-wrapper {
    @include wh(100%, 100%);
    display: flex;
    justify-content: space-between;
    align-items: center;
    &.border-line {
      box-sizing: border-box;
      border-bottom: 1px solid #f1f1f1;
    }
    .left {
      width: 4rem;
      display: flex;
      align-items: center;
      .icon {
        margin-right: 0.5rem;
      }
      .title {
        font-size: 0.7rem;
        color: black;
      }
    }
    .right {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      box-sizing: border-box;
      padding-right: 0.5rem;
    }
  }
}
</style>
