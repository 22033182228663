<template>
  <div class="log-item-box">
    <div class="control-info">
      <div class="info-detail">
        <span class="control-title">{{item.dataName}}</span>
        <span class="control-value-unit">{{item.dataUnit &&' ( '+item.dataUnit+' ) '}}</span>
        <span class="control-value">{{item.collectValue}}</span>
      </div>
      <div class="switch-btn" v-if="item.dataType==6">
        <van-switch :value="item.dataValue" :disabled='!item' @change='handleSwitch(item)' :activeValue="item.modelType == 1 ? '1' : '2'" :inactiveValue="item.modelType==2 ? '2' : '1'" active-color="#07c160" inactive-color="#ee0a24" size="1.1rem" />
      </div>
      <div class="button-btn" v-else>
        <van-button type="info" size='mini' @click="handlerButton">修改数值</van-button>
      </div>
    </div>
    <div>
      <van-collapse v-model="activeNames">
        <van-collapse-item title="上一次操作结果" name="1">
          <div v-if="item.dcLog">
            <div>
              <span>设备状态：</span>
              <span v-if="item.dcLog.dataValue==2" style="color:#F56C6C">关</span>
              <span v-if="item.dcLog.dataValue==1" style="color:#67C23A">开</span>
            </div>
            <div>
              <span>操作结果：</span>
              <span v-if="item.dcLog.handleType==0" style="color:#409EFF">处理中</span>
              <span v-else-if="item.dcLog.handleType==1" style="color:#67C23A">处理成功</span>
              <span v-else style="color:#F56C6C">处理失败</span>
            </div>

            <div>
              <div v-if="item.dcLog.handleType!='0'&&item.dcLog.handleType!='1'">
                <span>操作描述：</span>
                <span>{{item.dcLog.iotMessage}}</span>
              </div>
            </div>
            <div>
              <span>操作时间：</span>
              <span>{{item.dcLog.sendTime}}</span>
            </div>
            <div>
              <span>响应时间：</span>
              <span>{{item.dcLog.updateTime}}</span>
            </div>
          </div>
        </van-collapse-item>
      </van-collapse>
    </div>

    <van-dialog width='14rem' v-model="dialogShow" title="修改数值" @confirm='handleConfirm(item)' show-cancel-button confirm-button-text='下发控制' confirm-button-color='#1989fa'>
      <van-cell-group style="margin:0.5rem 0">
        <van-field label="请输入：" v-model="item.dataValue" />
      </van-cell-group>
    </van-dialog>
  </div>
</template>

<script>
import Vue from 'vue'
import { Dialog } from 'vant'
import { Field } from 'vant'
import 'vant/lib/dialog/style'
Vue.use(Dialog)
Vue.use(Field)
export default {
  name: 'ControlItem',
  props: {
    item: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      activeNames: [],
      dialogShow: false,
    }
  },
  methods: {
    handleSwitch(item) {
      Dialog.confirm({
        confirmButtonColor: '#1989fa',
        confirmButtonText: '下发控制',
        message:
          '你确定设置为' +
          ((item.modelType == 1 && item.dataValue == 1) ||
          (item.modelType == 2 && item.dataValue == 2)
            ? '关闭'
            : '开启') +
          '状态吗？',
      })
        .then(() => {
          this.$emit('handleSwitch', item)
        })
        .catch(() => {})
    },
    handlerButton() {
      this.dialogShow = true
    },
    handleConfirm(item) {
      this.dialogShow = false
      this.$emit('handleConfirm', item)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../../style/mixin';
.log-item-box {
  @include wh(100%, auto);
  border-radius: 4px;
  display: flex;
  box-sizing: border-box;
  padding: 0.5rem;
  flex-direction: column;
  .control-info {
    padding: 0.5rem;
    background: #fff;
    display: flex;
    align-items: center;

    .info-detail {
      flex: 1;
      .control-title {
        font-size: 0.8rem;
        color: black;
        margin-right: 0.1rem;
        font-weight: bold;
      }
      .control-tip {
        font-size: 0.7rem;
        color: red;
        margin-right: 0.5rem;
      }
      .control-value-unit {
        font-size: 0.5rem;
        font-weight: 600;
        margin-right: 0.5rem;
      }
      .control-value {
        color: #07c160;
        font-size: 0.7rem;
        margin-right: 0.5rem;
      }
    }
    .switch-btn {
      //   flex: 1;
      text-align: right;
      span {
        font-size: 0.7rem;
        color: black;
        margin-right: 0.5rem;
      }
      /deep/.van-switch {
        // width: 2.8rem;
        // height: 1.4rem;
        .van-switch__node {
          //   width: 1.4rem;
          //   height: 1.4rem;
        }
      }
    }
    .button-btn {
      //   flex: 1;
      text-align: right;
      span {
        font-size: 0.5rem;
        color: #fff;
      }
    }
  }
}
</style>
