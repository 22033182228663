<template>
  <div class="device-info-wrapper">
    <div class="device-info-header" v-if="hasData">
      <div class="icon">
        <img :src="imgUrl + deviceInfo.photoUrl" alt="" />
      </div>
      <div class="text">
        <div class="number">设备名称：{{ deviceInfo.deviceName }}</div>
        <div class="number">设备编号：{{ deviceInfo.deviceNo }}</div>
        <div class="date">启用日期：{{ deviceInfo.createTime }}</div>
        <div class="down-icon" @click="handleShowBasicInfo">
          <i class="iconfont icondown2" :class="basicShow ? 'transform180Animation' : 'transform0Animation'"></i>
        </div>
      </div>
    </div>
    <div class="basic-info-wrapper" v-if="hasData" :class="basicShow ? 'expandAnimation' : 'shrinkAnimation'">
      <InfoListPanel :data="basicData" />
    </div>
    <div class="manage-info-wrapper" v-if="hasData">
      <InfoListPanel :data="manageData" />
    </div>
    <div class="link-info-wrapper" v-if="hasData">
      <ListItem title="发起维修" @handleListItemClick="
          handleLinkClick(
            `/deviceRepair?type=sao&deviceName=${deviceInfo.name}&deviceNo=${deviceInfo.no}`
          )
        ">
        <i class="iconfont iconfaqi" slot="icon"></i>
        <i class="iconfont iconyou" slot="value"></i>
      </ListItem>
      <!-- <ListItem
        title="订阅该设备告警推送"
        @handleListItemClick="handleLinkClick('/subscribe')"
      >
        <i class="iconfont icondingyue" slot="icon"></i>
        <div class="status" slot="value">
          <span>已订阅</span>
          <i class="iconfont iconyou" slot="value"></i>
        </div>
      </ListItem> -->
    </div>
    <NoData v-if="!hasData" type="NoData" />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import moment from 'moment'
import InfoListPanel from './components/InfoListPanel'
import ListItem from '@/components/ListItem'
import { getDeviceDetail, deviceTypeEnumList } from '@/api/deviceDetail'
import { getEnums } from '@/api/deviceMonitor'
import { imgUrl } from '@/config/env'
import NoData from '@/components/NoData'

export default {
  name: 'DeviceInfo',
  components: {
    InfoListPanel,
    ListItem,
    NoData,
  },
  data() {
    return {
      imgUrl,
      deviceId: null,
      basicShow: false,
      hasData: true,

      basicData: {
        title: '基础信息',
        list: [
          {
            name: '生产厂家',
            value: '',
          },
          {
            name: '购置日期',
            value: '',
          },
          {
            name: '品牌',
            value: '',
          },
          {
            name: '型号',
            value: '',
          },
          {
            name: '序列号',
            value: '',
          },
        ],
      },
      manageData: {
        title: '管理信息',
        list: [
          {
            name: '设备类型',
            value: '',
          },
          {
            name: '设备状态',
            value: '',
          },
          //   {
          //     name: '管理状态',
          //     value: '',
          //   },
          {
            name: '安装位置',
            value: '',
          },
          {
            name: '所属部门',
            value: '',
          },
          {
            name: '分组',
            value: '',
          },
        ],
      },
      deviceInfo: {},
      statusData: {},
      manageStatusData: {},
      deviceTypeArr: {},
      deviceTypeKey: {},
    }
  },
  mounted() {
    this.deviceId = this.$route.query.id
    this.deviceDict()
    this.getDeviceTypeRequest()
  },
  methods: {
    ...mapActions('loading', ['setLoading']),
    handleShowBasicInfo() {
      this.basicShow = !this.basicShow
    },
    handleLinkClick(url) {
      this.$router.push({
        path: '/deviceRepair',
        query: {
          type: 'sao',
          deviceName: this.deviceInfo.deviceName,
          deviceNo: this.deviceInfo.deviceNo,
          deviceUniqueNo: this.deviceInfo.deviceUniqueNo,
        },
      })
    },
    /**
     * 获取设备类型
     */
    getDeviceTypeRequest() {
      getEnums(1)
        .then((res) => {
          res.forEach((item) => {
            this.deviceTypeArr[item.status] = item.label
          })
        })
        .finally(() => {
          this.getDeviceManageStatusRequest()
          this.getDeviceStatusRequest()
        })
    },
    /**
     * 获取设备状态
     */
    getDeviceStatusRequest() {
      this.setLoading(true)
      getEnums(5)
        .then((res) => {
          res.forEach((item) => {
            this.statusData[item.status] = item.label
          })
        })
        .finally(() => {
          this.getDeviceDetailRequest()
        })
    },
    /**
     * 获取管理状态
     */
    getDeviceManageStatusRequest() {
      this.setLoading(true)
      getEnums(4)
        .then((res) => {
          res.forEach((item) => {
            this.manageStatusData[item.status] = item.label
          })
        })
        .finally(() => {})
    },
    deviceDict() {
      deviceTypeEnumList().then((res) => {
        // this.deviceTypeList = res.details
        res.details.map((item) => {
          this.deviceTypeKey[item.status] = item.label
        })
      })
    },
    /**
     * 获取设备详情
     */
    getDeviceDetailRequest() {
      this.setLoading(true)
      getDeviceDetail(this.deviceId)
        .then((item) => {
          this.deviceInfo = item.details
          const res = item.details

          if (Object.keys(res).length <= 1) {
            this.hasData = false
          } else {
            this.hasData = true
          }
          this.basicData.list = [
            {
              name: '生产厂家',
              value: res.deviceSupplier,
            },
            {
              name: '购置日期',
              value:
                res.devicePurchaseDate &&
                moment(res.devicePurchaseDate).format('YYYY-MM-DD'),
            },
            {
              name: '品牌',
              value: res.deviceBrand,
            },
            {
              name: '型号',
              value: res.deviceModel,
            },
            {
              name: '序列号',
              value: res.deviceSerialNum,
            },
          ]
          this.manageData.list = [
            {
              name: '设备类型',
              value: this.deviceTypeKey[res.deviceType],
              //   value: this.deviceTypeArr[res.deviceType],
            },
            {
              name: '设备状态',
              value: this.statusData[res.liveStatus] || '--',
            },
            // {
            //   name: '管理状态',
            //   value: this.manageStatusData[res.manageStatus] || '--',
            // },
            {
              name: '安装位置',
              value: res.deviceLocationName,
            },
            {
              name: '所属部门',
              value: res.deptName,
            },
            {
              name: '分组',
              value: res.groupName,
            },
          ]
        })
        .catch(() => {
          this.hasData = false
        })
        .finally(() => {
          this.setLoading(false)
        })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../style/mixin';
.device-info-wrapper {
  @include wh(100%, 100%);
  .device-info-header {
    @include wh(100%, 6.5rem);
    background: linear-gradient(
      90deg,
      rgba(79, 147, 255, 1) 0%,
      rgba(58, 187, 253, 1) 100%
    );
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 1rem;
    .icon {
      @include wh(4rem, 4rem);
      background-color: white;
      border-radius: 0.3rem;
      overflow: hidden;
      @include CC;
      img {
        @include wh(100%, 100%);
      }
    }
    .text {
      flex: 1;
      box-sizing: border-box;
      padding-left: 0.7rem;
      line-height: 1.2rem;
      .title {
        font-size: 0.75rem;
        color: white;
      }
      .number {
        font-size: 0.65rem;
        color: white;
      }
      .date {
        font-size: 0.65rem;
        color: white;
      }
      .down-icon {
        box-sizing: border-box;
        padding-left: 3rem;
        margin-top: 0.4rem;
        i {
          font-size: 0.7rem;
          color: white;
          width: 0.7rem;
          height: 1rem;
          display: block;
          &.transform180Animation {
            animation: transform180Animation 0.5s forwards;
          }
          &.transform0Animation {
            animation: transform0Animation 0.5s forwards;
          }
        }
      }
    }
  }
  .basic-info-wrapper {
    margin-bottom: 0.6rem;
    height: 12rem;
    overflow: hidden;
    &.expandAnimation {
      animation: expandAnimation 0.5s forwards;
    }
    &.shrinkAnimation {
      animation: shrinkAnimation 0.5s forwards;
    }
  }
  .link-info-wrapper {
    background-color: white;
    margin-bottom: 1rem;
    /deep/.list-item-wrapper {
      height: 2.7rem;
      .left {
        width: 50%;
        .icon {
          i {
            color: #4a8cff;
            font-size: 1.1rem;
          }
        }
        .title {
          font-size: 0.8rem;
          margin-top: 0.2rem;
        }
      }
    }
    .status {
      span {
        color: #4a8cff;
        font-size: 0.75rem;
        margin-right: 1rem;
      }
    }
    .iconyou {
      font-size: 0.7rem;
      color: #999999;
    }
  }
}

@keyframes transform180Animation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-180deg);
  }
}

@keyframes transform0Animation {
  from {
    transform: rotate(-180deg);
  }
  to {
    transform: rotate(0deg);
  }
}

@keyframes expandAnimation {
  from {
    height: 0;
  }
  to {
    height: 12rem;
  }
}

@keyframes shrinkAnimation {
  from {
    height: 12rem;
  }
  to {
    height: 0;
  }
}
</style>
