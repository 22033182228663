<template>
  <div class="data-monitor-wrapper">
    <div class="has-data-wrapper" v-if="hasData">
      <div class="basic-info-wrapper">
        <div class="search-list-wrapper" ref="searchBox">
          <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
            <ControlItem v-for="(item, index) in list" :key="'ControlItem' + index" :title="item" :item="item" :index="index" @handleSwitch='handleSwitch' @handleConfirm='handleConfirm' />
          </van-list>
        </div>
      </div>

    </div>
    <NoData v-if="!hasData" type="NoData" />
  </div>
</template>

<script>
import Vue from 'vue'
import { mapActions } from 'vuex'
import ControlItem from './components/ControlItem'
import NoData from '@/components/NoData'
import { Notify, Collapse, CollapseItem, List } from 'vant'
import 'vant/lib/list/style'
import {
  deviceControlActionConfig_control,
  getDataControlList,
} from '@/api/deviceDetail'
Vue.use(Notify)
Vue.use(Collapse)
Vue.use(CollapseItem)
Vue.use(List)

export default {
  name: 'DataMonitor',
  components: {
    ControlItem,
    NoData,
  },
  data() {
    return {
      checked: false,
      deviceId: '',
      hasData: true,
      list: [],
      loading: false,
      finished: false,
      params: {
        page: 0,
        size: 10,
        sort: 'id,asc',
        deviceId: '',
      },
    }
  },
  mounted() {
    let deviceId = this.$route.query.id
    this.deviceId = deviceId
    this.params.deviceId = deviceId
  },
  methods: {
    ...mapActions('loading', ['setLoading']),

    handleSwitch(item) {
      let param = {
        id: item.id,
        deviceId: item.deviceId,
        dataCode: item.dataCode,
        dataValue: item.dataValue == 1 ? '2' : '1',
        retainValue: item.retainValue,
      }

      deviceControlActionConfig_control(param).then((res) => {
        if (res.retCode == 1) {
          this.params.page = 0
          this.getDataControlListRequest()
          Notify({ type: 'success', message: res.retMsg })
        } else {
          Notify({ type: 'danger', message: res.retMsg })
        }
      })
    },
    handleConfirm(row) {
      let param = {
        id: row.id,
        deviceId: row.deviceId,
        dataCode: row.dataCode,
        dataValue: row.dataValue,
        retainValue: row.retainValue,
      }
      deviceControlActionConfig_control(param).then((res) => {
        if (res.retCode == '1') {
          this.params.page = 0
          this.getDataControlListRequest()

          Notify({ type: 'success', message: res.retMsg })
        } else {
          Notify({ type: 'danger', message: res.retMsg })
        }
      })
    },
    onLoad() {
      setTimeout(() => {
        this.getDataControlListRequest()
      }, 500)
    },
    getDataControlListRequest() {
      if (this.params.page === 0) {
        this.list = []
      }
      getDataControlList(this.params).then((res) => {
        const { content, totalElements } = res
        content.forEach((item) => {
          this.list.push(item)
        })
        this.loading = false
        if (this.list.length >= totalElements) {
          this.finished = true
          this.loading = true
        }
        this.params.page = this.params.page + 1
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../style/mixin';
.data-monitor-wrapper {
  width: 100%;
  height: 100%;
  .has-data-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .basic-info-wrapper {
    // height: 0;
    // flex: 1;
    display: flex;
    flex-direction: column;
    .control-info {
      padding: 0.5rem;
      background: #fff;
      display: flex;
      align-items: center;

      .control-title {
        font-size: 0.7rem;
        color: black;
        margin-right: 0.5rem;
      }
      .control-tip {
        font-size: 0.7rem;
        color: red;
        margin-right: 0.5rem;
      }
      .switch-btn {
        flex: 1;
        text-align: right;
        span {
          font-size: 0.7rem;
          color: black;
          margin-right: 0.5rem;
        }
        /deep/.van-switch {
          width: 2.8rem;
          height: 1.4rem;
          .van-switch__node {
            width: 1.4rem;
            height: 1.4rem;
          }
        }
      }
    }
    .title {
      @include wh(100%, 2.2rem);
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      padding: 0 1rem;
      border-top: 1px solid #e6e6e6;
      border-bottom: 1px solid #e6e6e6;
      h2 {
        font-size: 0.8rem;
        color: black;
      }
      .switch-btn {
        display: flex;
        align-items: center;
        span {
          font-size: 0.7rem;
          color: black;
          margin-right: 0.5rem;
        }
        /deep/.van-switch {
          width: 2.8rem;
          height: 1.4rem;
          .van-switch__node {
            width: 1.4rem;
            height: 1.4rem;
          }
        }
      }
    }
    .list {
      height: 0;
      flex: 1;
      background-color: white;
      overflow-y: auto;
      .list-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        box-sizing: border-box;
        padding-left: 1rem;
        .value {
          font-size: 0.7rem;
          color: #333333;
          &.red {
            color: #ff3333;
          }
        }
        .uni {
          font-size: 0.7rem;
          color: #999999;
        }
      }
    }
  }
  .trend-wrapper {
    .title {
      @include wh(100%, 2.2rem);
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      padding: 0 1rem;
      border-top: 1px solid #e6e6e6;
      border-bottom: 1px solid #e6e6e6;
      h2 {
        font-size: 0.8rem;
        color: black;
      }
    }
    .table {
      background-color: white;
      .table-title {
        @include wh(100%, 2.2rem);
        box-sizing: border-box;
        padding: 0 1rem;
        display: flex;
        align-items: center;
        h3 {
          font-size: 0.7rem;
          color: black;
          margin-right: 0.5rem;
        }
        span {
          font-size: 0.6rem;
          color: #999999;
        }
      }
      .table-content {
        @include wh(100%, 13rem);
      }
    }
  }
}
</style>
